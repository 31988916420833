import React from "react";

export default () => (
		<div className="container-fluid">
			<div className="row">
				<div className="col-12" id="bar-parent">
					<div id="bar"></div>
				</div>
			</div>
		</div>
);